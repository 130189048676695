.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ScrollbarsCustom {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.ScrollbarsCustom .thumb{
  background: #40bcd0 !important;
}
.ScrollbarsCustom .wrapper .content{
  top: -12px !important;
}
.surahName, .bismillah{
  direction: "rtl";
  font-family: "Lateef";
  font-size: 35px;
  color: #3594a3;
}
.ayaat {
  font-family: "Lateef";
  font-size: 24px;
  color: #3594a3
}
.mobile-none{
  display: block;
}
.mobile-block{
  display: none;
}
@media screen  and (max-width: 768px){
  .ScrollbarsCustom .wrapper .content{
    padding-left: 24px !important;
  }
  .mobile-block{
    display: flex;
  }
  .settings svg{
    color: #3594a3;
  }
  .mobile-none{
    display: none;
  }
  .surahName, .bismillah{
    font-size: 25px;
  }
  .ayaat {
    font-size: 20px;
  }
}  

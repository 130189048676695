.Translation.heading {
  direction: rtl;
  font-family: "Scheherazade";
}
.Translation .ayah.ur,
.Translation .ayah.ar,
.Translation .ayah.dv,
.Translation .ayah.fa,
.Translation .ayah.ug {
  font-family: "Lateef";
  font-size: 35px;
  direction: rtl;
  border: 1px solid #e2e2e2;
  padding: 15px 20px;
  text-align: right;
}

.Translation .ayah {
  font-family: "Tiro Bangla";
  font-size: 22px;
  border: 1px solid #e2e2e2;
  padding: 15px 20px;
  color: #1da4b9;
  text-align: left;
}

@media (max-width: 768px) {
  .Translation .ayah {
    font-size: 18px;
  }
}

.highlight .Translation.heading{
  background-color: #3594a3;
}
.highlight .Translation .ayah{
  color: #fff;
}

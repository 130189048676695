.Translations {
    margin-top:20px;
}

.Translations .optiontext {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 25px;
}
.Translations .optiontext.ar,
.Translations .optiontext.ur,
.Translations .optiontext.ug,
.Translations .optiontext.fa {
  font-family: "Lateef";
  text-align: right;
  font-size: 25px;
}

.Translations .optiontext__placeholder,
.Translations .optiontext__single-value{
  font-size: 18px;
}



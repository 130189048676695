body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ScrollbarsCustom {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.ScrollbarsCustom .thumb{
  background: #40bcd0 !important;
}
.ScrollbarsCustom .wrapper .content{
  top: -12px !important;
}
.surahName, .bismillah{
  direction: "rtl";
  font-family: "Lateef";
  font-size: 35px;
  color: #3594a3;
}
.ayaat {
  font-family: "Lateef";
  font-size: 24px;
  color: #3594a3
}
.mobile-none{
  display: block;
}
.mobile-block{
  display: none;
}
@media screen  and (max-width: 768px){
  .ScrollbarsCustom .wrapper .content{
    padding-left: 24px !important;
  }
  .mobile-block{
    display: flex;
  }
  .settings svg{
    color: #3594a3;
  }
  .mobile-none{
    display: none;
  }
  .surahName, .bismillah{
    font-size: 25px;
  }
  .ayaat {
    font-size: 20px;
  }
}  

.Surah {
    margin-top: 20px;
}
.Surah .surahName {
  font-family: "Lateef";
  font-size: 25px;
}

.Verse {
    margin-top:20px;
}

.Translations {
    margin-top:20px;
}

.Translations .optiontext {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 25px;
}
.Translations .optiontext.ar,
.Translations .optiontext.ur,
.Translations .optiontext.ug,
.Translations .optiontext.fa {
  font-family: "Lateef";
  text-align: right;
  font-size: 25px;
}

.Translations .optiontext__placeholder,
.Translations .optiontext__single-value{
  font-size: 18px;
}



.LeftPanel .tabPanel {
  margin-top: 20px;
  margin-bottom: 20px;
}
.LeftPanel .editions {
  margin-top: 20px;
}
.LeftPanel{
  display: block;
}
@media screen and (max-width:768px) {
  .LeftPanel{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 15%;
    right: 0;
    background: rgb(255, 255, 255);
    z-index: 9;
    padding: 10px;
    transform: translateX(100%);
    width: 85%;
    transition: all ease .3s;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .LeftPanel.showPanel{
    transform: translateX(0);
  }
}

.Search {
  margin-top: 20px;
  display: flex;
}
.Search input {
  width: 100%;
  margin-right: 10px;
  padding: 0 10px;
  border: 1px solid #9c9898;
  border-radius: 4px;
}

.Verse {
  position: relative;
}
.Verse.heading {
  direction: rtl;
  font-family: "Scheherazade";
}
.Verse .ayah.ur,
.Verse .ayah.ar {
  font-family: "Lateef";
  font-size: 50px;
  direction: rtl;
  border: 1px solid #e2e2e2;
  padding: 10px 15px 0 15px;
}
.Verse .ayah {
  font-family: "Lateef";
  font-size: 50px;
  border: 1px solid #e2e2e2;
  padding: 10px 15px 0 15px;
  direction: rtl;
}
.highlight .Verse{
  background: #3594a3;
}
.highlight .Verse .ayah{
  color: #fff;
}
.Verse .ayahContainer {
  width: 35px;
  display: inline-block;
  margin-right: 5px;
}
.Verse .ayahStop {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  padding: 50% 0;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  background: #3594a3;
  color: white;
  font-family: "Arial Black, sans";
  font-size: 14px;
  text-align: center;
  line-height: 0px;
}
.highlight .Verse .ayahStop{
  background: #43b2c4;
}
.lastSeen{
  cursor: pointer;
}
.lastSeen svg{
  font-size: 28px;
}
.Verse .wordbywordContainer {
  display: flex;
  justify-content: right;
  flex-flow: row wrap;
}
.Verse .ayahWord {
  padding: 10px;
  margin: 5px;
  background: #b7d8ef;
}
.Verse .wordbywordContainer + .ayahContainer {
  position: absolute;
  left: 10px;
  top: 30%;
}

.Verse span.h,
.Verse span.s,
.Verse span.l {
  color: #aaaaaa;
  cursor: pointer;
}
.Verse span.n {
  color: #537fff;
  cursor: pointer;
}

.Verse span.p {
  color: #4050ff;
  cursor: pointer;
}

.Verse span.q {
  color: #dd0008;
  cursor: pointer;
}

.Verse span.o {
  color: #2144c1;
  cursor: pointer;
}

.Verse span.c {
  color: #d500b7;
  cursor: pointer;
}
.Verse span.f {
  color: #9400a8;
  cursor: pointer;
}

.Verse span.w {
  color: #58b800;
  cursor: pointer;
}

.Verse span.i {
  color: #26bffd;
  cursor: pointer;
}

.Verse span.a {
  color: #169200;
  cursor: pointer;
}

.Verse span.u {
  color: #169200;
  cursor: pointer;
}

.Verse span.d {
  color: #a1a1a1;
  cursor: pointer;
}

.Verse span.b {
  color: #a1a1a1;
  cursor: pointer;
}

.Verse span.g {
  color: #ff7e1e;
  cursor: pointer;
}

.Verse .ayah span div {
  /* display: inline; */
}
@media screen and (max-width:768px) {
  .Verse .ayah {
      font-size: 32px;
      padding: 10px 15px 0 15px;
  }
}
.Translation.heading {
  direction: rtl;
  font-family: "Scheherazade";
}
.Translation .ayah.ur,
.Translation .ayah.ar,
.Translation .ayah.dv,
.Translation .ayah.fa,
.Translation .ayah.ug {
  font-family: "Lateef";
  font-size: 35px;
  direction: rtl;
  border: 1px solid #e2e2e2;
  padding: 15px 20px;
  text-align: right;
}

.Translation .ayah {
  font-family: "Tiro Bangla";
  font-size: 22px;
  border: 1px solid #e2e2e2;
  padding: 15px 20px;
  color: #1da4b9;
  text-align: left;
}

@media (max-width: 768px) {
  .Translation .ayah {
    font-size: 18px;
  }
}

.highlight .Translation.heading{
  background-color: #3594a3;
}
.highlight .Translation .ayah{
  color: #fff;
}

@keyframes marquee1 {
    0% {
      transform: translateX(100%); }
    100% {
      transform: translateX(-100%); } }
  
  @keyframes marquee2 {
    0% {
      transform: translateX(100%); }
    100% {
      transform: translateX(-200%); } }
  
  @keyframes marquee3 {
    0% {
      transform: translateX(100%); }
    100% {
      transform: translateX(-300%); } }
  
  @keyframes marquee4 {
    0% {
      transform: translateX(100%); }
    100% {
      transform: translateX(-400%); } }
  
  @keyframes marquee5 {
    0% {
      transform: translateX(100%); }
    100% {
      transform: translateX(-500%); } }
  
  .noselect {
    cursor: default;
    /* http://stackoverflow.com/a/4407335/4956731 */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    /* Konqueror */
    /* Firefox */
    /* IE/Edge */
    user-select: none;
    /* non-prefixed version, currently
                                    not supported by any browser */ }
  
  .hidden {
    display: none !important; }
  
  .audio_player {
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: rgb(24 68 75 / 50%);
    width: 100%;
    position: fixed;
    bottom: 0;
    /* http://codepen.io/aralon/pen/NqGWXZ */ }
    .audio_player .spacer {
      width: 10px; }
    .audio_player .audio_progress_container {
      position: relative;
      height: 100%;
      flex-grow: 1; }
    .audio_player .audio_progress {
      background-color: #3594a3;
      height: 100%;
      width: 0;
      will-change: width; 
    }
    .audio_player .audio_progress_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .audio_player .audio_info_marquee {
      overflow: hidden;
      /* to make room for .audio_time_progress */
      width: calc(100% - 180px);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px; }
    .audio_player .audio_info {
      display: none;
      width: 100%;
      white-space: nowrap;
      word-break: break-all;
      margin: 0;
      font-size: 19.2px;
      color: #fff; }
    .audio_player .audio_time_progress {
      margin-right: 20px;
      align-self: center;
      color: #fff;
      font-size: 23px; }
    .audio_player .audio_button {
      padding-left: 11px;
      padding-right: 11px;
      margin-left: 4px;
      margin-right: 4px;
      cursor: pointer;
      align-self: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 50px; }
    .audio_player .play_pause_button .play_pause_inner {
      height: 30px;
      width: 30px;
      overflow: hidden;
      position: relative; }
    .audio_player .play_pause_button .left {
      height: 100%;
      float: left;
      background-color: #fff;
      width: 36%;
      transition: all 0.25s ease;
      overflow: hidden; }
    .audio_player .play_pause_button .triangle_1 {
      transform: translate(0, -100%); }
    .audio_player .play_pause_button .triangle_2 {
      transform: translate(0, 100%); }
    .audio_player .play_pause_button .triangle_1,
    .audio_player .play_pause_button .triangle_2 {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      width: 0;
      height: 0;
      border-right: 30px solid rgb(24 68 75 / 50%);
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      transition: transform 0.25s ease; }
    .audio_player .play_pause_button .right {
      height: 100%;
      float: right;
      width: 36%;
      background-color: #fff;
      transition: all 0.25s ease; }
    .audio_player .play_pause_button.paused .left {
      width: 50%; }
    .audio_player .play_pause_button.paused .right {
      width: 50%; }
    .audio_player .play_pause_button.paused .triangle_1 {
      transform: translate(0, -50%); }
    .audio_player .play_pause_button.paused .triangle_2 {
      transform: translate(0, 50%); }
    .audio_player .skip_button.back {
      transform: rotate(180deg); }
    .audio_player .skip_button .skip_button_inner {
      display: flex;
      flex-direction: row; }
    .audio_player .skip_button .right_facing_triangle {
      width: 0;
      height: 0;
      border-left: 16px solid #fff;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent; }
    @media screen and (min-width: 370px) {
      .audio_player .audio_info {
        display: initial;
        animation: marquee5 10s cubic-bezier(0, 0.23, 0.6, -0.09) infinite; } }
    @media screen and (min-width: 450px) {
      .audio_player .audio_info {
        animation: marquee4 10s cubic-bezier(0, 0.23, 0.7, -0.09) infinite; } }
    @media screen and (min-width: 550px) {
      .audio_player .audio_info {
        animation: marquee3 10s cubic-bezier(0, 0.23, 0.85, -0.09) infinite; } }
    @media screen and (min-width: 650px) {
      .audio_player .audio_info {
        animation: marquee2 10s cubic-bezier(0, 0.23, 1, -0.09) infinite; } }
    @media screen and (min-width: 720px) {
      .audio_player .audio_info {
        animation: initial; } }
    @media screen  and (max-width: 768px){
      .audio_player {
        left: 0;
      }
    }    
  
  
  /*# sourceMappingURL=audioplayer.css.map*/
.SearchResults .top {
  height: 60px;
}

.SearchResults .top h3 {
  line-height: 80px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 25px;
  color: #3594a3;
}
.SearchResults .searchResults .result {
  background: #e2e2e2;
  padding: 20px;
  margin-top: 20px;
}

